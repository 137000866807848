export const chunk = function (arr, size) {
  var temp = arr.slice(0),
    results = []

  while (temp.length) {
    results.push(temp.splice(0, size))
  }
  return results
}

export const splitArrayIntoChunksOfLen = function (arr, size) {
  var chunks = [],
    i = 0,
    n = arr.length
  while (i < n) {
    chunks.push(arr.slice(i, (i += size)))
  }
  return chunks
}

export const splitToChunks = function (array, parts) {
  let result = []
  let a = array
  for (let i = parts; i > 0; i--) {
    result.push(a.splice(0, Math.ceil(a.length / i)))
  }
  return result
}

export const sortData=(a,b)=>{
  if ( a.name < b.name ){
    return -1;
  }
  if ( a.name > b.name ){
    return 1;
  }
  return 0;
}