import React from "react"

const H3 = ({ title, className ,textSize="lg:text-3xl md:text-3xl text-2xl"}) => (
  <h3
    className={`font-serif font-semibold ${textSize} tracking-normal leading-8 ${className}`}
    dangerouslySetInnerHTML={{ __html: title }}
  />
)

export default H3
