import React from "react"

const P = ({ title, className, noPad, noCenter ,textSize="text-lg"}) => (
  <p
    className={`${className}  ${noPad ? "" : "pt-6"} ${textSize}
    ${noCenter ? "" : "mx-auto"}
     font-sans max-w-4xl`}
    dangerouslySetInnerHTML={{ __html: title }}
  />
)

export default P
