import React from "react"

const H2 = ({ title, className, children, theme, textColor, index }) => {
  if (!theme) {
    theme = textColor ? textColor : "#24262B"
  }
  return (
    index === 0 ? 
      <h1
        className={`font-serif font-bold lg:text-5xl tracking-normal text-4xl ${className}`}
        style={{ color: theme, lineHeight: "1.20" }}
        dangerouslySetInnerHTML={{ __html: title }}
      /> :
      <h2
        className={`font-serif font-bold lg:text-5xl tracking-normal text-4xl ${className}`}
        style={{ color: theme, lineHeight: "1.20" }}
        dangerouslySetInnerHTML={{ __html: title }}
      />
  )
}

export default H2
