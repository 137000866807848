export const banPopUp = [
  "/meet-us-at-ces",
  "/meet-us-at-ces/",
  "/meet-us-in-usa",
  "/meet-us-in-usa/",
  "/career",
  "/career/",
  "/contact-us",
  "/contact-us/",
  "/thank-you",
  "/thank-you/",
  "/hire-react-native-developers-ads-page",
  "/hire-react-native-developers-ads-page/",
  "/hire-laravel-developers-ads-page",
  "/hire-laravel-developers-ads-pag/",
  "/hire-mern-stack-developers-ads-page",
  "/hire-mern-stack-developers-ads-page/",
  "/hire-nodejs-developers-ads-pagek",
  "/hire-nodejs-developers-ads-pagek/",
  "/hire-devops-developers-ads-page",
  "/hire-devops-developers-ads-page/",
  "/hire-qa-tester-ads-page",
  "/hire-qa-tester-ads-page/",
  "/qa-services-ads-page",
  "/qa-services-ads-page/",
  "/hire-reactjs-developers-ads-page",
  "/hire-reactjs-developers-ads-page/",
  "/london-tech-week",
  "/london-tech-week/",
]

export const addsLinks = [
  "/hire-react-native-developers-ads-page",
  "/hire-react-native-developers-ads-page/",
  "/hire-laravel-developers-ads-page",
  "/hire-laravel-developers-ads-pag/",
  "/hire-mern-stack-developers-ads-page",
  "/hire-mern-stack-developers-ads-page/",
  "/hire-nodejs-developers-ads-pagek",
  "/hire-nodejs-developers-ads-pagek/",
  "/hire-devops-developers-ads-page",
  "/hire-devops-developers-ads-page/",
  "/hire-qa-tester-ads-page",
  "/hire-qa-tester-ads-page/",
  "/qa-services-ads-page",
  "/qa-services-ads-page/",
  "/hire-reactjs-developers-ads-page",
  "/hire-reactjs-developers-ads-page/",
]

export const nofollowLink=[
  "/hire-react-native-developers-ads-page",
  "/hire-react-native-developers-ads-page/",
  "/hire-laravel-developers-ads-page",
  "/hire-laravel-developers-ads-pag/",
  "/hire-mern-stack-developers-ads-page",
  "/hire-mern-stack-developers-ads-page/",
  "/hire-nodejs-developers-ads-pagek",
  "/hire-nodejs-developers-ads-pagek/",
  "/hire-devops-developers-ads-page",
  "/hire-devops-developers-ads-page/",
  "/hire-qa-tester-ads-page",
  "/hire-qa-tester-ads-page/",
  "/qa-services-ads-page",
  "/qa-services-ads-page/",
  "/hire-reactjs-developers-ads-page",
  "/hire-reactjs-developers-ads-page/",
  "/thank-you/",
  "/meet-us-in-usa/",
  "/meet-us-in-usa"
]

export const gitexLink = ["/gitex-technology-week", "/gitex-technology-week/"]
export const gitexLink2022 = ["/meet-us-at-ces", "/meet-us-at-ces/"]
export const gitexLink2023 = ["/london-tech-week", "/london-tech-week/"]
export const USA2023 = ["/meet-us-in-usa", "/meet-us-in-usa/"]
export const caseStudyLink = ["/casestudy/taxi-app-like-uber/", "/casestudy/taxi-app-like-uber"]

export const hireLink = [
  "/hire-laravel-developer/",
  "/hire-react-js-developer/",
  "/hire-react-native-developer/",
  "/hire-node-js-developer/",
  "/hire-flutter-developer/",
  "/hire-django-developer/",
  "/hire-python-developer/",
  "/hire-angular-js-developer/",
  "/hire-vue-js-developer/",
  "/hire-electron-js-developer/",
  "/hire-mern-stack-developer/"
]
