export const logo_images = [
    {
        src:"https://webmobtechcdn.nyc3.cdn.digitaloceanspaces.com/wmt_v4/2023/04/trinity_logo.webp",
        alt:"Trinity"
    },
    {
        src:"https://webmobtechcdn.nyc3.cdn.digitaloceanspaces.com/wmt_v4/2023/04/Honeywell_logo.webp ",
        alt:"Honeywell"
    },
    {
        src:"https://webmobtechcdn.nyc3.cdn.digitaloceanspaces.com/wmt_v4/2023/04/6thstreet_logo.webp",
        alt:"6thstreet"
    },
    {
        src:"https://webmobtechcdn.nyc3.cdn.digitaloceanspaces.com/wmt_v4/2023/04/LPGA_logo.webp ",
        alt:"LPGA"
    },
    {
        src:"https://webmobtechcdn.nyc3.cdn.digitaloceanspaces.com/wmt_v4/2023/04/Merkel_science_logo-.webp",
        alt:"Merkel science"
    },
    {
        src:"https://webmobtechcdn.nyc3.cdn.digitaloceanspaces.com/wmt_v4/2023/04/MishaNonoo_logo.webp ",
        alt:"Misha Nonoo "
    },
    {
        src:"https://webmobtechcdn.nyc3.cdn.digitaloceanspaces.com/wmt_v4/2023/04/Cabanalife_logo.webp ",
        alt:"Cabanalife "
    },
    {
        src:"https://webmobtechcdn.nyc3.cdn.digitaloceanspaces.com/wmt_v4/2023/04/Houzquest_logo.webp",
        alt:"Houzquest"
    },
    {
        src:"https://webmobtechcdn.nyc3.cdn.digitaloceanspaces.com/wmt_v4/2023/04/SwitchIT_logo.webp",
        alt:"Switch IT"
    },
    {
        src:"https://webmobtechcdn.nyc3.cdn.digitaloceanspaces.com/wmt_v4/2023/04/Primally_nourished_logo.webp",
        alt:"Primally nourished"
    },
    {
        src:"https://webmobtechcdn.nyc3.cdn.digitaloceanspaces.com/wmt_v4/2023/05/pentair-contact.webp",
        alt:"Pentair Contact"
    },
    {
        src:"https://webmobtechcdn.nyc3.cdn.digitaloceanspaces.com/wmt_v4/2023/05/Apparel-group-Contact.webp",
        alt:"Apparel Group"
    }
]
export const footer_logo_images = [
    {
        src:"https://webmobtechcdn.nyc3.cdn.digitaloceanspaces.com/wmt_v4/2022/10/trinity-footer.png",
        alt:"Trinity"
    },
    {
        src:"https://webmobtechcdn.nyc3.cdn.digitaloceanspaces.com/wmt_v4/2022/10/honeywell-footer.png",
        alt:"Honeywell"
    },
    {
        src:"https://webmobtechcdn.nyc3.cdn.digitaloceanspaces.com/wmt_v4/2022/10/6th-street-footer.png",
        alt:"6thstreet"
    },
    {
        src:"https://webmobtechcdn.nyc3.cdn.digitaloceanspaces.com/wmt_v4/2022/10/lpga_footer.png",
        alt:"LPGA"
    },
    {
        src:"https://webmobtechcdn.nyc3.cdn.digitaloceanspaces.com/wmt_v4/2022/10/markle-science-footer.png",
        alt:"Merkel science"
    },
    {
        src:"https://webmobtechcdn.nyc3.cdn.digitaloceanspaces.com/wmt_v4/2022/10/misha-nonoo-footer.png",
        alt:"Misha Nonoo "
    },
    {
        src:"https://webmobtechcdn.nyc3.cdn.digitaloceanspaces.com/wmt_v4/2022/10/cabana-life-footer.png",
        alt:"Cabanalife "
    },
    {
        src:"https://webmobtechcdn.nyc3.cdn.digitaloceanspaces.com/wmt_v4/2022/10/houzquest-footer.png",
        alt:"Houzquest"
    },
    {
        src:"https://webmobtechcdn.nyc3.cdn.digitaloceanspaces.com/wmt_v4/2022/10/switch-it-footer.png",
        alt:"Switch IT"
    },
    {
        src:"https://webmobtechcdn.nyc3.cdn.digitaloceanspaces.com/wmt_v4/2022/10/primally-nourished-footer.png",
        alt:"Primally nourished"
    },
    {
        src:"https://webmobtechcdn.nyc3.cdn.digitaloceanspaces.com/wmt_v4/2023/05/pentair-footer.webp",
        alt:"Pentair"
    },
    {
        src:"https://webmobtechcdn.nyc3.cdn.digitaloceanspaces.com/wmt_v4/2023/05/Apparel-group-footer.webp",
        alt:"Apparel Group"
    }
]
export const categorizes = ["Startup", "SME", "Enterprise", "Agency"]
export const Approx_Budget = ["Less Then $10,000", "$10,000 to $25,000", "$25,000 to $50,000", "$50,000+"]
export const How_soon =["Right now","In few weeks","In few months","Not sure"]